<template>
  <div>
    <SettingsHeader
      title="Master Wallet"
      description="Internal wallet for merchant deposits"
      style="margin-bottom: 20px;"
    />
    <div class="body">
      <PublicKeysItem :coin-info="btcInfo" />
      <PublicKeysItem :coin-info="ltcInfo" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import { useAdmin } from '@/composables/useAdmin';

import SettingsHeader from '../../SettingsHeader.vue';

import PublicKeysItem from './PublicKeysItem.vue';

export default {
  name: 'PublicKeys',
  components: {
    PublicKeysItem,
    SettingsHeader,
  },
  setup() {
    const { adminSettings } = useAdmin();

    const btcInfo = computed(() => ({
      ...adminSettings?.value?.masterWallet?.BTC,
      title: 'Bitcoin',
      shortKey: 'btc',
    }));
    const ltcInfo = computed(() => ({
      ...adminSettings?.value?.masterWallet?.LTC,
      title: 'Litecoin',
      shortKey: 'ltc',
    }));

    return {
      btcInfo,
      ltcInfo,
    };
  },
};
</script>

<style scoped lang="scss">
.body {
  border-radius: 8px;
  border: 1px solid var(--color-black-01);

  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
