import { useAdminStore } from '@/store';
import { useFetch } from '@/api';

import { processCryptoRates, processFiatRates } from './helpers';

export const getRates = async () => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, data, errorObject } = await axiosWrapper({
    type: 'get',
    url: '/rates/current/all',
    params: { isFreshResponse: true },
  });

  delete data.crypto.timestamp;
  delete data.fiat.timestamp;

  const processedData = {
    crypto: processCryptoRates(data.crypto),
    forex: processFiatRates(data.fiat),
    isFailed: {
      fixer: data.fiat.fixer.failed,
      exchangeRate: data.fiat.exchangeRate.failed,
    },
  };

  return { isSuccess, data: processedData, errorObject };
};

export const getAdminSettings = async () => {
  const store = useAdminStore();
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: '/settings/admin',
    params: { isFreshResponse: true },
  });

  if (isSuccess) {
    store.$patch({ settings: data });
  }

  return { isSuccess, data };
};

export const changePubKeys = async (params) => {
  const store = useAdminStore();
  const { axiosWrapper, successHandler } = useFetch();
  const { data, isSuccess, errorObject } = await axiosWrapper({
    type: 'patch',
    url: '/settings/admin/m-w/pub-k/add',
    params,
  });

  if (isSuccess) {
    store.$patch({ settings: data });
  }

  return {
    data, isSuccess, errorObject, successHandler,
  };
};

export const toggleMaintenance = async ({ isActive }) => {
  const store = useAdminStore();
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'patch',
    url: '/settings/admin/maintenance/toggle',
    params: { isActive },
    successMessage: 'Settings updated',
  });

  if (isSuccess) {
    store.$patch({ settings: { isMaintenanceEnabled: data.isMaintenanceEnabled, isSignUpEnabled: data.isSignUpEnabled } });
  }

  return { isSuccess, data };
};

export const toggleSignUp = async ({ isActive }) => {
  const store = useAdminStore();
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'patch',
    url: '/settings/admin/signup/toggle',
    params: { isActive },
    successMessage: 'Settings updated',
  });

  if (isSuccess) {
    store.$patch({ settings: { isSignUpEnabled: data.isSignUpEnabled, isMaintenanceEnabled: data.isMaintenanceEnabled } });
  }

  return { isSuccess, data };
};

export const setMailer = async (value) => {
  const store = useAdminStore();
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'patch',
    url: '/settings/admin/system-mailer',
    params: { activeSystemMailer: value },
    successMessage: 'Settings updated',
  });

  if (isSuccess) {
    store.$patch({ settings: { activeSystemMailer: data.activeSystemMailer } });
  }

  return { isSuccess, data };
};

export const setAdminSettings = async (params) => {
  const store = useAdminStore();
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'patch',
    url: '/settings/admin',
    params,
    successMessage: 'Rule updated',
  });

  if (isSuccess) {
    store.$patch({ settings: { payments: data.payments } });
  }

  return { data, isSuccess };
};

export const changeRules = async (params) => {
  const store = useAdminStore();
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'patch',
    url: '/settings/admin/rules',
    params,
    successMessage: 'Rule updated',
  });

  if (isSuccess) {
    store.$patch({ settings: { rules: data.rules } });
  }

  return { data, isSuccess };
};

export const toggleCoinsAvailability = async (params) => {
  const store = useAdminStore();
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'put',
    url: '/settings/admin/coins/availability',
    params,
    successMessage: 'Rule updated',
  });

  if (isSuccess) {
    store.$patch({ settings: { availableCoins: data } });
  }

  return { data, isSuccess };
};

export const getCountriesList = async () => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: '/settings/admin/countries',
  });

  const processedData = data.map((item) => ({
    text: item.name,
    value: item.code,
  }));

  return { isSuccess, data: processedData };
};

export const onUpdateBillingInfo = async ({ country, name }) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'post',
    url: '/settings/admin/billing',
    params: { country, name },
    successMessage: 'Billing info was updated',
  });

  return { data, isSuccess };
};

export const onUpdateLimiterOption = async ({ limit }) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'put',
    url: '/settings/admin/activePayment/limit',
    params: { limit },
    successMessage: 'Limit was updated',
  });

  return { data, isSuccess };
};

export const onUpdatePrintBillingLegalInfo = async (val) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'put',
    url: '/settings/admin/billing/print',
    params: { printBillingLegalInfo: val },
    successMessage: `Print company info was ${val ? 'enabled' : 'disabled'}`,
  });

  return { data, isSuccess };
};
