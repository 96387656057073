<template>
  <div class="monitors-wrapper">
    <div class="monitor">
      <div class="monitor-header">
        <AppText size="18px" class="font-medium" variant="div" mb="8px">
          Crypto Markets
        </AppText>
        <AppText color="#808080">
          Source for real-time market data. Rates averaged via multiple sources
        </AppText>
      </div>

      <SimpleTable :data="crypto" :columns="cryptoColumns" :is-loading="isLoading">
        <template #main="{ column, item, row }">
          <div v-if="column.name === 'coin'" class="d-flex align-items-center">
            <AppIcon
              v-if="row.isPrimary"
              :name="row.icon"
              size="20px"
            />
            <FamilyIcon
              v-else
              :icon="row.icon"
              :family-icon="row.familyIcon"
              size="20px"
            />
            <AppText class="font-medium" style="margin-left: 12px;">
              {{ getCurrencyName(row) }}
            </AppText>
            <AppBadge
              v-if="!row.isPrimary"
              :type="COLORS.EXPIRED"
              style="border: unset; margin-left: 8px;"
            >
              <div>
                <AppText size="12px" class="font-medium" color="var(--color-black-04)">
                  {{ row.family }}
                </AppText>
              </div>
            </AppBadge>
          </div>
          <CurrencyFormatter
            v-if="column.type === 'currency'"
            :value="item"
            :format="numberFormat"
            is-account-currency
            :type="numberTypes.FLOAT2"
            size="13px"
            class="font-medium"
          />
          <div v-if="column.name === 'sources'" class="d-flex flex-row" style="gap: 7px">
            <template v-for="source in item" :key="source">
              <AppTooltip :is-enabled="!source.value" cursor="default">
                <template #text>
                  This source is not responding
                </template>

                <AppBadge :type="source.value ? COLORS.EXPIRED : COLORS.FAIL" style="border: unset;">
                  <AppText color="var(--color-black)" class="is-capitalized">
                    {{ source.text }}
                  </AppText>
                </AppBadge>
              </AppTooltip>
            </template>
            <template v-if="!item.length">
              <div class="dash-line" />
            </template>
          </div>
        </template>
      </SimpleTable>
    </div>

    <div class="monitor">
      <div class="monitor-header">
        <AppText size="18px" class="font-medium" variant="div" mb="8px">
          FX Markets
        </AppText>
        <AppText color="#808080">
          Foreign exchange rates feed
        </AppText>
      </div>

      <SimpleTable :data="forex" :columns="forexColumns" :is-loading="isLoading">
        <template #column="{ column }">
          <AppTooltip :is-enabled="isFailed[column.name]" cursor="default">
            <template #text>
              This source is not responding
            </template>

            <div class="flex-center">
              <AppText size="12px" type="uppercase" class="font-medium">
                {{ column.title }}
              </AppText>
              <DotSeparator v-if="isFailed[column.name]" color="var(--color-E9A848)" margin="0 0 0 8px" size="8px" />
            </div>
          </AppTooltip>
        </template>
        <template #main="{ row, column, item }">
          <div v-if="column.name === 'currency'" class="d-flex align-items-center">
            <div style="width: 50px;" class="d-flex align-items-center">
              <AppIcon
                v-if="row.currency === 'USD'"
                name="ellipse-star"
                size="32px"
                is-img-tag
              />
              <CountryFlag
                v-else
                :value="getCountryCode(item)"
                size="32px"
              />
            </div>
            <AppText
              size="15px"
              :class="row.currency === 'USD' ? 'font-semibold' : 'font-medium'"
              :color="row.currency === 'USD' ? 'var(--color-949494)' : 'var(--color-black)'"
              style="width: 88px"
            >
              {{ item }}
            </AppText>
            <AppText
              :class="row.currency === 'USD' ? 'font-medium' : ''"
              :color="row.currency === 'USD' ? 'var(--color-949494)' : 'var(--color-black)'"
            >
              {{ getFullnameCurrency(item) }}
            </AppText>
          </div>
          <AppText
            v-if="['exchangeRate', 'fixer'].includes(column.name)"
            class="rate-data"
            :class="[row.currency === 'USD' ? 'is-usd font-semibold' : 'font-medium', isFailed[column.name] ? 'is-failed' : '']"
            size="15px"
            :color="row.currency === 'USD' ? 'var(--color-949494)' : 'var(--color-black)'"
          >
            {{ item }}
          </AppText>
        </template>
      </SimpleTable>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue';

import CountryFlag from '@/components/Icon/CountryFlag.vue';
import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';
import DotSeparator from '@/components/DotSeparator.vue';
import SimpleTable from '@/components/MainTable/SimpleTable.vue';
import AppTooltip from '@/components/AppTooltip.vue';
import FamilyIcon from '@/components/Icon/FamilyIcon.vue';

import { i18n } from '@/plugins/localization';
import { currencyList, numberTypes } from '@/common/data';
import { TABLE_TYPES } from '@/common/tableTypes';
import { COLORS } from '@/common/status';
import { useAdmin } from '@/composables/useAdmin';

export default defineComponent({
  name: 'SettingsMonitors',
  components: {
    SimpleTable,
    CurrencyFormatter,
    CountryFlag,
    AppTooltip,
    DotSeparator,
    FamilyIcon,
    AppBadge: defineAsyncComponent(() => import('@/components/AppBadge.vue')),
  },
  props: {
    crypto: {
      type: Array,
      default: () => [],
      required: true,
    },
    forex: {
      type: Array,
      default: () => [],
      required: true,
    },
    isFailed: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const cryptoColumns = [
      {
        name: 'coin', type: TABLE_TYPES.CRYPTO, title: 'Coin', width: 210,
      },
      {
        name: 'rate', type: TABLE_TYPES.CURRENCY, title: 'Rate', width: 178,
      },
      { name: 'sources', title: 'Sources', width: 304 },
    ];

    const forexColumns = [
      { name: 'currency', title: 'Currency' },
      { name: 'fixer', title: 'Fixer.io' },
      { name: 'exchangeRate', title: 'Exchangerate-api.com' },
    ];

    const { numberFormat } = useAdmin();

    const getCountryCode = (currency) => {
      const selectedCurrency = currencyList.find((item) => item.short === currency);
      return selectedCurrency?.countryCode;
    };

    const { t } = i18n.global;
    const getFullnameCurrency = (currency) => {
      const selectedCurrency = currencyList.find((item) => item.short === currency);
      return t(`currency.${selectedCurrency.countryCode}`);
    };

    const getCurrencyName = (currency) => {
      if (currency.value === 'ETH_ETH') {
        return currency.shortValue;
      }
      return currency.isPrimary ? currency.value : currency.name;
    };

    return {
      cryptoColumns,
      forexColumns,
      numberFormat,
      COLORS,

      getCountryCode,
      getFullnameCurrency,
      getCurrencyName,
      numberTypes,
    };
  },
});
</script>

<style scoped lang="scss">
.monitors-wrapper {
  padding: 30px 40px;
  max-width: 800px;
}

.monitor {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}
.monitor-header {
  margin-bottom: 20px;
}

:deep(.table__wrap ) {
  min-height: 280px;
  .table__row__cell {
    height: 44px;
  }
}

.rate-data.is-failed {
  opacity: 0.2;
  text-decoration: line-through;
  &.is-usd {
    opacity: 0.4;
  }
}

.dash-line {
  border-top: 1px solid var(--color-black-03);
  width: 13px;
}

</style>
