<template>
  <SmoothModal
    :model-value="isModalOpen"
    width="440px"
    :has-header="false"
    @update:model-value="closeModal"
  >
    <div style="padding-top: 30px">
      <div class="warning-icon-wrap">
        <AppIcon name="warning" size="24px" />
      </div>
    </div>

    <AppText
      variant="div"
      size="17px"
      class="font-medium"
      mt="25px"
    >
      Delete {{ coin.title }} pubkey
    </AppText>

    <AppText variant="div" :line-height="1.5" mt="20px">
      Deleting the {{ coin.title }} pubkey immediately disables new registrations for merchants. Both Bitcoin and Litecoin pubkeys must be connected in order to enable merchant registrations.
    </AppText>

    <AppText variant="div" :line-height="1.5" mt="20px">
      Existing merchants will continue to have the previously assigned deposit addresses prior to this action.
    </AppText>

    <AppText variant="div" :line-height="1.5" mt="20px">
      Funds in the wallet are not affected by this.
    </AppText>

    <AppText variant="div" mt="20px" mb="12px">
      Enter TOTP
    </AppText>

    <ActionForm
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      :response-errors="fieldsErrors"
      class="form"
      @validate="onRemoveKey"
    >
      <template #default="{ errors, action }">
        <SplitInput
          v-model="requestFields.code"
          type="dashed"
          :state="errors?.code ? 'error' : ''"
          is-auto-clear-error
          :error-message="errors.code?.message"
        />

        <div class="d-flex" style="gap: 24px;">
          <FButton is-full-width type="danger" @click="action">
            Delete pubkey
          </FButton>
          <FButton is-full-width type="plain" @click="closeModal">
            Cancel
          </FButton>
        </div>
      </template>
    </ActionForm>
  </SmoothModal>
</template>

<script setup>
import SmoothModal from '@/components/SmoothModal';
import SplitInput from '@/components/Inputs/SplitInput.vue';

import { ActionForm, codeRule, fieldValidation } from '@/validation';

import { changePubKeys } from '../../api';

const props = defineProps({
  coin: {
    type: Object,
    default: () => {},
  },
});

defineEmits(['remove-key']);

const isModalOpen = defineModel({ type: Boolean, default: false });

const {
  validationSchema,
  requestFields,
  fieldsErrors,
  clearState,
} = fieldValidation({ fieldName: 'code', rule: codeRule });

const onRemoveKey = async () => {
  const { isSuccess, successHandler } = await changePubKeys({
    [props.coin.shortKey === 'btc' ? 'btcPubKey' : undefined]: null,
    [props.coin.shortKey === 'ltc' ? 'ltcPubKey' : undefined]: null,
    totpCode: requestFields.code,
  });

  if (isSuccess) {
    isModalOpen.value = false;
    successHandler('Key deleted');
  }
};

const closeModal = () => {
  clearState();
  isModalOpen.value = false;
};
</script>

<style scoped lang="scss">
.warning-icon-wrap {
  width: 40px;
  height: 40px;
  background: var(--color-D05B5B33);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
