import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { coinsSortWeight, currencyList, fullCoinsArray } from '@/common/data';

export const processCryptoRates = (incomeData) => {
  dayjs.extend(relativeTime);

  const crypto = fullCoinsArray
    .map((coin) => {
      const coinName = (() => {
        if (coin.value === 'ETH_ETH') {
          return coin.shortValue;
        }
        return coin.isPrimary ? coin.value : coin.name;
      })();

      const selectedCoin = incomeData[`${coinName}USD`];
      const rate = coin.isPrimary ? Math.round((selectedCoin.avg + Number.EPSILON) * 100) / 100 : selectedCoin.avg;
      const sources = Object.entries(selectedCoin.rates).map((el) => ({ text: el[0], value: selectedCoin.failed ? null : el[1] }));
      const lastUpdated = dayjs(selectedCoin.timestamp).fromNow();

      return {
        ...coin,
        ...selectedCoin,
        rate,
        sources,
        lastUpdated,
      };
    })
    .sort((a, b) => coinsSortWeight[a.coin] - coinsSortWeight[b.coin]);

  return crypto;
};

export const processFiatRates = (incomeData) => {
  let rates = [];
  let sideRates = [];

  if (incomeData.fixer?.rates && Object.entries(incomeData.fixer.rates).length) {
    rates = Object.entries(incomeData.fixer.rates);
    sideRates = incomeData.exchangeRate.rates;
  }
  if (incomeData.exchangeRate?.rates && Object.entries(incomeData.exchangeRate.rates)) {
    rates = Object.entries(incomeData.exchangeRate.rates);
    sideRates = incomeData.fixer.rates;
  }

  const forex = rates
    .map((element) => {
      const [currency, exchangeRate] = element;

      const fixer = sideRates[currency];
      const currencyName = currencyList.find((el) => el.short === currency);

      return {
        currency,
        currencyName,
        exchangeRate,
        fixer,
      };
    });

  return forex;
};
