<template>
  <div class="warn">
    <AppIcon name="warning" size="36px" />

    <div>
      <AppText variant="div" size="15px" class="font-medium">
        Master wallet setup incomplete
      </AppText>

      <AppText variant="div" mt="8px">
        Merchant registrations remain disabled until the master wallet is fully setup.
      </AppText>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingsWarning',
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.warn {
  height: 80px;
  padding: 0 40px;
  background: var(--color-error-01);
  display: flex;
  align-items: center;
  gap: 20px;
}
</style>
