<template>
  <div class="item" :class="{ 'is-empty': !isKeyAdded }" @click="onClick">
    <div class="cell">
      <div style="position: relative;">
        <AppIcon :key="coinInfo?.xpub" :name="`${coinInfo?.shortKey}-${coinInfo?.xpub ? 'circle' : 'disabled'}`" size="32px" is-img-tag />
        <div v-if="!isKeyAdded" class="plus-icon flex-center">
          <AppIcon name="plus-add" size="14px" :opacity="0.4" />
        </div>
      </div>
    </div>
    <div class="cell">
      <div class="d-flex flex-column" style="margin-left: 16px; gap: 8px;">
        <div class="d-flex align-items-center" :style="`${isKeyAdded ? '2px' : '0px'};`">
          <AppText
            variant="div"
            size="15px"
            class="font-medium"
            :opacity="isKeyAdded ? 1 : 0.4"
            mr="4px"
          >
            {{ coinInfo?.title }}
          </AppText>

          <TooltipCopy v-if="coinInfo?.xpub" :text="coinInfo?.xpub">
            <AppText color="var(--color-black-04)">
              ({{ trimedPubKey }})
            </AppText>
          </TooltipCopy>
        </div>
        <AppText v-if="coinInfo?.generatedAt" color="var(--color-black-05)">
          {{ isKeyAdded ? 'Added' : 'Last key removed' }} on <DateFormatter :date="updatedAt" :format="dateTimeFormat" />
        </AppText>
      </div>
    </div>
    <FSpacer />
    <div class="cell">
      <div v-if="isKeyAdded" class="key-added">
        <div class="flex-center" style="gap: 4px;">
          <AppIcon name="key-long" fill="var(--color-black-05)" size="15px" />
          <AppText size="12px" color="var(--color-black-05)">
            Key <span class="font-medium">#{{ coinInfo?.rank }}</span>
          </AppText>
        </div>

        <DotSeparator color="var(--color-CCCCCC)" margin="0 16px" />

        <div class="flex-center" style="gap: 4px; margin-right: 16px;">
          <AppIcon name="wallet-deposit" fill="var(--color-black-05)" size="15px" />
          <AppText size="12px" color="var(--color-black-05)">
            <span class="font-medium">{{ coinInfo?.index }}</span>
            Deposit {{ t('common.address', coinInfo?.index) }}
          </AppText>
        </div>

        <FDropdown placement="bottom-start" class="dots-menu">
          <template #title>
            <AppIcon name="dots-menu" size="18px" class="dots-icon" />
          </template>

          <template #content>
            <div class="dropdown-years">
              <DropdownItem @click="isRemoveModalOpen = true">
                <AppText>
                  Delete public key
                </AppText>
              </DropdownItem>
            </div>
          </template>
        </FDropdown>
      </div>

      <div v-else class="no-key" tabindex="0" @keypress.space="onSpacePress">
        <AppText color="var(--color-primary)" class="font-medium">
          Pair new pubkey (#{{ coinInfo?.rank + 1 }})
        </AppText>
        <div class="arrow-icon">
          <AppIcon name="arrow-bracket" class="rotate-90" size="18px" />
        </div>
      </div>
    </div>

    <AddModal v-model="isAddModalOpen" :coin="coinInfo" />
    <PublicKeyRemoveModal v-model="isRemoveModalOpen" :coin="coinInfo" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import dayjs from 'dayjs';

import DotSeparator from '@/components/DotSeparator.vue';
import DropdownItem from '@/components/ElementUI/DropdownItem.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';

import { truncateLongString } from '@/utils/functions';
import { useProfile } from '@/composables/useProfile';

import AddModal from './AddModal.vue';
import PublicKeyRemoveModal from './PublicKeyRemoveModal.vue';

const props = defineProps({
  coinInfo: {
    type: Object,
    default: () => {},
  },
});

const isKeyAdded = computed(() => Boolean(props.coinInfo?.xpub));
const updatedAt = computed(() => dayjs(props.coinInfo?.generatedAt).format(''));

const trimedPubKey = computed(() => truncateLongString(props.coinInfo?.xpub, {
  threshold: 5, startLength: 4, endLength: 4, dots: '.....',
}));

const { dateTimeFormat } = useProfile();

const isAddModalOpen = ref(false);
const isRemoveModalOpen = ref(false);

const onClick = () => {
  if (!isKeyAdded.value) {
    isAddModalOpen.value = true;
  }
};

const onSpacePress = (e) => {
  e.preventDefault();
  onClick();
};
</script>

<style scoped lang="scss">
.item {
  background: var(--color-white);
  height: 80px;
  display: flex;
  border-bottom: 1px solid var(--color-black-01);
  @include transition-base(background);

  :deep(.text), :deep(.icon) {
    @include transition-base(opacity);
  }

  &:hover.is-empty {
    cursor: pointer;
    background: var(--color-F7F7F7);

    :deep(.text), :deep(.icon) {
      opacity: 1;
    }

    .arrow-icon {
      background-color: var(--color-primary);
      :deep(.icon path) {
        fill: var(--color-white);
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .cell {
    display: flex;
    align-items: center;

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }

    .no-key {
      @include flex-center;
      @include focus-visible;
    }

    .key-added {
      flex-grow: 1;
      display: flex;
      align-items: center;

      :deep(.text) {
        word-break: break-word;
        flex-grow: 1;
      }
    }
  }
}

.plus-icon {
  width: 16px;
  height: 16px;
  @include flex-center;

  background-color: var(--color-white);
  box-shadow: 0px 1px 3px 0px var(--color-black-01);
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -4px;
}

.arrow-icon {
  background-color: var(--color-primary-01);

  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 16px;
  @include flex-center;

  @include transition-base(background-color);
  :deep(.icon path) {
    fill: var(--color-primary);
    @include transition-base(stroke);
  }
}

.dots-menu {
  :deep(.f-dropdown--title) {
    padding: 8px;
  }
  &:hover {
    .dots-icon {
      :deep(g) {
        opacity: 1;
      }
    }
  }
}
.dots-icon {
  transform: rotate(90deg);
}
</style>
