<template>
  <SmoothModal
    :model-value="isModalOpen"
    width="480px"
    :has-header="false"
    @update:model-value="closeModal"
  >
    <div style="padding: 30px 0 25px">
      <div class="warning-icon-wrap">
        <AppIcon :name="`${coin.shortKey}-circle`" size="40px" is-img-tag />
      </div>
    </div>

    <AppText
      variant="div"
      size="17px"
      class="font-medium"
      mb="16px"
    >
      Pair {{ coin.title }} pubkey #{{ coin?.rank + 1 }}
    </AppText>

    <AppText variant="div" :line-height="1.5" mb="32px">
      New merchant registrations after this step will immediately start to be associated with deposit addresses related to this public key. Deposit addresses generated for older merchants are not affected by this step.
    </AppText>

    <div style="margin-bottom: 30px;">
      <div
        class="d-flex align-items-center"
        style="margin-bottom: 10px; gap: 12px; cursor: pointer;"
        @click="override = !override"
      >
        <FSwitch :model-value="override" is-small />
        <AppText class="font-medium">
          Override all existing deposit addresses
        </AppText>
      </div>
      <AppText color="var(--color-black-05)">
        This function replaces the deposit addresses for all existing merchants with addresses derived from the new pubkey. Maintenance mode must be enabled.
      </AppText>
    </div>

    <ActionForm
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      :response-errors="fieldsErrors"
      @validate="onAddKey"
    >
      <template #default="{ errors, action }">
        <FInput
          v-model="requestFields.pubkey"
          label="Enter pubkey in BIP49 format"
          type="textarea"
          maxlength="111"
          :validation-error="errors.pubkey"
        />

        <div class="d-flex" style="gap: 24px; margin-top: 30px;">
          <FButton is-full-width @click="action">
            Pair pubkey
          </FButton>
          <FButton is-full-width type="plain" @click="closeModal">
            Cancel
          </FButton>
        </div>
      </template>
    </ActionForm>
  </SmoothModal>
</template>

<script setup>
import { ref } from 'vue';

import SmoothModal from '@/components/SmoothModal';

import { ActionForm, bip49Rule, fieldValidation } from '@/validation';

import { changePubKeys } from '../../api';

const props = defineProps({
  coin: {
    type: Object,
    default: () => {},
  },
});

defineEmits(['add-key']);

const isModalOpen = defineModel({ type: Boolean, default: false });
const override = ref(true);

const {
  validationSchema,
  requestFields,
  fieldsErrors,
  clearState,
  processError,
} = fieldValidation({ fieldName: 'pubkey', rule: bip49Rule });

const onAddKey = async () => {
  const payload = {};

  if (props.coin.shortKey === 'btc') {
    payload.btcPubKey = requestFields.pubkey;
    payload.overrideBTC = override.value;
  }

  if (props.coin.shortKey === 'ltc') {
    payload.ltcPubKey = requestFields.pubkey;
    payload.overrideLTC = override.value;
  }

  const { isSuccess, errorObject, successHandler } = await changePubKeys(payload);

  if (isSuccess) {
    closeModal();
    successHandler('Key added');
  } else {
    processError(errorObject, errorObject.error?.data?.message, 'pubkey');
  }
};

const closeModal = () => {
  clearState();
  isModalOpen.value = false;
};
</script>
