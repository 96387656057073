<template>
  <div>
    <transition name="show">
      <SettingsWarning v-if="!isKeysAvailable" />
    </transition>

    <div class="master">
      <PublicKeys v-if="isSuperAdminRole" style="margin-bottom: 40px;" />

      <MasterContract style="margin-bottom: 10px;" />

      <MainSettings />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import { useAdmin } from '@/composables/useAdmin';
import { useToken } from '@/composables/useToken';

import SettingsWarning from '../SettingsWarning.vue';

import MainSettings from './MainSettings/MainSettings.vue';
import MasterContract from './MasterContract.vue';
import PublicKeys from './PublicKeys/PublicKeys.vue';

export default {
  name: 'SettingsMaster',
  components: {
    SettingsWarning,
    PublicKeys,
    MasterContract,
    MainSettings,
  },
  setup() {
    const { adminSettings } = useAdmin();
    const { isSuperAdminRole } = useToken();

    const isKeysAvailable = computed(() => (adminSettings?.value?.masterWallet?.BTC?.xpub && adminSettings?.value?.masterWallet?.LTC?.xpub));

    return { isKeysAvailable, isSuperAdminRole };
  },
};
</script>

<style scoped lang="scss">
.master {
  padding: 30px 0 0 40px;
  max-width: 860px;
}
</style>
